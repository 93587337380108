<template>
  <div>
    <div v-show="state === states.READY">
      <BasePageHeader v-if="state === states.READY">
        <div>
          <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
          <BasePageHeading>Start Subscription</BasePageHeading>
        </div>
      </BasePageHeader>
      <BaseWrapper>
        <form
          ref="form"
          class="max-w-lg mx-auto"
          novalidate
          @submit.prevent
        >
          <div
            id="payment-element"
            class="mb-6"
          >
            <!-- Mount the Payment Element here -->
          </div>

          <div
            v-if="state === states.READY"
            class="grid grid-cols-3 p-4 bg-white border rounded-lg"
          >
            <div>
              <p class="mb-4">
                <span class="px-2 py-1 text-sm text-white rounded bg-secondary">
                  {{ plan.name }}
                </span>
              </p>
              <p class="text-3xl font-bold leading-none">
                {{ formatted(plan.products.fixed.price.unit_amount, currency) }}
              </p>
              <div v-if="taxRates && taxRates.length > 0">
                <p
                  v-for="taxRate in taxRates"
                  :key="taxRate.id"
                  class="text-xs font-bold tracking-wide text-gray-500"
                >
                  + {{ taxRate.description }}
                  <br>
                  {{ formatCurrency(plan.products.fixed.price.unit_amount / 100 * taxRate.percentage / 100, currency) }}
                </p>
              </div>
            </div>
            <div class="text-sm">
              <p class="font-medium">
                Assessment credits
              </p>
              <ul>
                <li>{{ numberFormat(plan.assessment_credits) }} included</li>
                <li>{{ formatted(plan.products.assessments.price.unit_amount, currency) }} each thereafter</li>
              </ul>
            </div>
            <div class="text-sm">
              <p class="font-medium">
                Candidate credits
              </p>
              <ul>
                <li>{{ numberFormat(plan.candidate_credits) }} included</li>
                <li>{{ formatted(plan.products.candidates.price.unit_amount, currency) }} each thereafter</li>
              </ul>
            </div>
          </div>

          <div class="mt-4">
            <BaseButton
              type="green"
              class="justify-center w-full"
              @click="submit()"
            >
              Start Subscription
            </BaseButton>
          </div>

          <p
            v-if="errorMessage"
            class="mt-4"
          >
            {{ errorMessage }}
          </p>
        </form>
      </BaseWrapper>
    </div>
    <div v-if="state === states.LOADING">
      <BasePageHeader>
        <div>
          <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
          <BasePageHeading>Start Subscription</BasePageHeading>
        </div>
      </BasePageHeader>
      <Loader />
      <p
        v-if="loadingMessage"
        class="mt-4 text-center"
      >
        {{ loadingMessage }}
        <br>
        <span class="text-sm">The page may refresh</span>
      </p>
    </div>
    <div v-else-if="state === states.ERROR">
      <BaseErrorBlock />
    </div>
  </div>
</template>

<script>
import Loader from '@components/Loader'

import states from '@api/states'
import { mapGetters } from 'vuex'
import { formatCurrencyWhole, formatCurrency } from '@utils/currency'
import plansApi from '@api/plans'
import checkoutApi from '@api/checkout'
import { numberFormat } from '@utils/numberFormat'

const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_TC)

export default {
  components: {
    Loader
  },

  data() {
    return {
      states,
      numberFormat,
      formatCurrency,

      error: null,
      errorMessage: null,
      loadingMessage: null,

      plans: null,
      taxRates: null,
      stripeElements: null,
      stripePaymentElement: null,
      stripeFormReady: false,
      stripeFormLoading: false
    }
  },

  computed: {
    ...mapGetters({
      organisationName: 'organisations/name'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      // Normally with breadcrumbs() we only use this when the page has
      // fully loaded, but on the checkout it makes a nice consistency if
      // the breadcrumb is always there, so we only require that the
      // organisationName is there for use
      if (!this.organisationName) {
        return []
      }

      return [
        {
          name: this.organisationName
        },
        {
          name: 'Plans',
          to: 'settings-payg-plan'
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (!this.plans) {
        return states.LOADING
      }
      if (!this.stripeFormReady) {
        return states.LOADING
      }
      if (this.stripeFormLoading) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * @return {string}
     */
    currency() {
      return this.$route.params.currency.toUpperCase()
    },

    /**
     * @return {string}
     */
    planSlug() {
      return this.$route.params.plan
    },

    /**
     * Returns the plans but only includes prices for the currency selected
     *
     * @return {Array}
     */
    currencyPlans() {
      return this.plans.map(plan => {
        plan.products.fixed.price = plan.products.fixed.prices.find(price => price.currency === this.currency)
        plan.products.assessments.price = plan.products.assessments.prices.find(price => price.currency === this.currency)
        plan.products.candidates.price = plan.products.candidates.prices.find(price => price.currency === this.currency)

        return plan
      })
    },

    /**
     * Returns the plan and prices for this plan/currency
     *
     * @return {Object}
     */
    plan() {
      if (!this.currencyPlans || !this.planSlug) {
        return
      }

      return this.currencyPlans.find(plan => plan.slug === this.planSlug)
    },

    /**
     * The page to redirect the user back to after card auth
     */
    checkoutUrl() {
      const path = this.$router.resolve({
        name: 'settings-checkout-complete',
        params: {
          currency: this.currency.toLowerCase(),
          plan: this.planSlug
        }
      }).href
      return window.location.origin + path
    }
  },

  mounted() {
    this.loadPlans()
    this.setupCustomer()
  },

  methods: {
    /**
     * Load in products if missing
     */
    loadPlans() {
      plansApi.paygIndex()
        .then(response => {
          this.plans = response.data.plans
        })
        .catch(error => {
          this.error = error
          throw error
        })
    },

    /**
     * Setup the customer and PaymentIntent
     */
    setupCustomer() {
      this.loadingMessage = 'Preparing checkout'

      checkoutApi.createPaymentIntent({
        currency: this.currency,
        planSlug: this.planSlug
      })
        .then(response => {
          this.loadingMessage = null
          this.setupStripe(response.data.clientSecret)
          this.taxRates = response.data.taxRates
        })
        .catch(error => {
          this.loadingMessage = null
          this.error = error
          throw error
        })
    },

    setupStripe(clientSecret) {
      this.loadingMessage = 'Loading card form'

      this.stripeElements = stripe.elements({
        clientSecret,
        appearance: {
          theme: 'stripe'
        }
      })
      this.stripePaymentElement = this.stripeElements.create('payment')
      this.stripePaymentElement.mount('#payment-element')
      this.stripePaymentElement.on('ready', () => {
        this.stripeFormReady = true
        this.loadingMessage = null
      })
    },

    /**
     * Submit the form!
     */
    submit() {
      this.errorMessage = null
      this.stripeFormLoading = true
      this.loadingMessage = 'Processing your payment'

      stripe
        .confirmPayment({
          elements: this.stripeElements,
          confirmParams: {
            return_url: this.checkoutUrl
          }
        })
        .then((result) => {
          if (result.error) {
            console.error('Stripe card error:', result.error.code)
            if (result.error.message) {
              this.errorMessage = result.error.message
              return
            }
            this.error = result.error
            throw result.error
          }
          console.log('result', result)
        })
        .catch(error => {
          this.error = error
          throw error
        })
        .finally(() => {
          this.stripeFormLoading = false
          this.loadingMessage = null
        })
    },

    /**
     * @param {object} currencies
     * @return {string}
     */
    formatted(amount, currency) {
      if (!currency) {
        return amount / 100
      }
      return formatCurrencyWhole(amount / 100, currency)
    }
  }
}
</script>
